import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Snackbar, Button, Slide, Grid, CircularProgress } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  cleanSales,
  downloadExcelSales,
  createPibox,
  listSalesShopify,
  listAllSales,
  goToFile,
  getOptimizeRouteSelected,
  getOptimizeRouteFilters,
  getOrdersAllFilters
} from 'context/sales/actions';
import { message as closeMessage } from 'context/commons/actions';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import SalesTable from 'components/Tables/Sales';
import SalesTableFilters from 'components/Tables/Sales/Filters';
import Loading from 'components/Loading';
import Sidebar from 'components/Sidebar';
import BackdropSync from 'components/Modals/BackdropSync';
import { modalMessage } from 'utils';
import Form from './Form';

const Sales = ({ history }) => {
  const { translate } = useContext(I18nContext);
  const [
    {
      sales: { loading, list, saleList },
      commons: { message },
    },
    dispatch,
  ] = useStateValue();
  const [per_page] = useState(PER_PAGE);
  const [isSync, setIsSync] = useState(false);
  const [filters, setFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [selected, setSelected] = useState([]);
  const [updateAddress, setUpdateAddress] = useState(null);
  const [haveData, setHaveData] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFileAll, setLoadingFileAll] = useState(false);
  const [order, setOrder] = useState('asc');

  const getSales = useCallback(
    async ({page, isUpdating = null}) => {
      listAllSales(dispatch, {
        page,
        per_page,
        order_direction:order,
        update: isUpdating,
        ...filters,
      });
    },
    [dispatch, per_page, filters, order]
  );

  useEffect(() => {
    getSales({page:INITIAL_PAGE});
    return () => closeMessage(dispatch, null);
  }, [getSales, dispatch, order]);

  useEffect(() => {
    const interval = setInterval(() => {
      // list && list.synchronizing && getSales(INITIAL_PAGE);
    }, 7500);
    return () => clearInterval(interval);
  }, [getSales, dispatch, list]);

  useEffect(() => {
    setHaveData(true);
    return () => cleanSales(dispatch);
  }, [dispatch]);

  useEffect(() => {
    saleList && history.push('/app/bookings/new');
  }, [saleList, history, dispatch]);

  const onFilters = (params) => setFilters(params);

  const handleChangeCheck = (checks, item) => {
    setShowButton(Object.values(checks).some((j) => j === true));

    if (item.checked) {
      setSelected((prev) => prev.concat({ ...item, integration: item.order?.integrator }));
    } else {
      setSelected((prev) => prev.filter((r) => r._id !== item._id));
    }
  };

  const closeCreatePiboxButton = ()=>{
    setShowButton(false);
  }

  const handleAddress = (sale) => {
    setUpdateAddress(sale);
    setOpen(true);
  };

  const goToCreatePibox = () => {
    createPibox(dispatch, selected, true);
  };

  const sendSyncSales = async () => {
    setIsSync(true);
    getSales({page:INITIAL_PAGE, isUpdating:true});
  };

  const downloadExcel = async () =>
    await downloadExcelSales(dispatch, { ...filters });

  const syncShopify = async () => {
    const filtersShopify = {
      ...filters,
      update: true,
    };
    listSalesShopify(dispatch, {
      page: INITIAL_PAGE,
      per_page,
      ...filtersShopify,
    });
  };

  const onClose = async () => {
    setIsSync(false);// getSales({page:currentPage, order_direction:newOrder});
    getSales(INITIAL_PAGE);
  }

  const goToOptimize = async (type) => {
    if (type === 1 && !filters?.city_id) {
      modalMessage(
        translate('ups'),
        translate('sales.optimize.youMustChooseAFilterByCity'),
        '',
        translate('close'),
        true
      )
    } else {
      setLoadingFile(true)
      let callback = null;
      type === 0 ? callback = await getOptimizeRouteSelected(dispatch, selected) : callback = await getOptimizeRouteFilters(dispatch, { page: INITIAL_PAGE, per_page, ...filters });
      callback?.status ?
        modalMessage(
          translate('sales.optimize.excelGeneratedSuccessfully'),
          translate('sales.optimize.message.description'),
          'success',
          translate('continue'),
          false,
          true,
          translate('sales.optimize.btn.downloadFile')
        ).then(async (response) => {
          setLoadingFile(false);
          if (response.isConfirmed) {
            goToFile(dispatch, callback?.urlExcel)
            history.push('/app/operations/optimize-routes');
          } else {
            window.open(callback?.urlExcel, '_blank');
          }
        })
      : setLoadingFile(false)
    }
  }

  const goToOrdersAll = async (type) => {
    if (type === 1 && !filters?.city_id) {
      modalMessage(
        translate('ups'),
        translate('sales.optimize.youMustChooseAFilterByCity'),
        '',
        translate('close'),
        true
      )
    } else {
      setLoadingFileAll(true)
      let callback = null;
      type === 0 ? callback = await getOptimizeRouteSelected(dispatch, selected) : callback = await getOrdersAllFilters(dispatch, { page: INITIAL_PAGE, per_page, ...filters });
      callback?.status ?
        modalMessage(
          translate('sales.optimize.excelGeneratedSuccessfully'),
          '',
          '',
          translate('sales.optimize.btn.downloadFile'),
          true
          
        ).then(async (response) => {
          setLoadingFileAll(false);
          window.open(callback?.urlExcel, '_blank');
        })
      : setLoadingFileAll(false)
    }
  }

  return (
    <div className="padding-page-full-height">
      {isSync &&
        <BackdropSync
          text="app.sync.text"
          onClose={onClose}
        />
      }
      <Grid container spacing={1}>
        <Grid item md={8} xs={12}>
          <TitleWithDowload
            title={translate('app.sales')}
            icon={
              <TrendingUpIcon className="align-center" style={{ marginRight: 7 }} />
            }
            downloadExcel={downloadExcel}
            buttonSync={translate('app.sales.synchronizeSales')}
            sync={sendSyncSales}
            syncShopify={syncShopify}
            showOptionsShopify={filters?.store_name && true}
          />
        </Grid>
        <Grid item md={2} xs={12} className="text-right-sm">
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => goToOptimize(selected?.length > 0 ? 0 : 1)} 
            disabled={loadingFile}
            startIcon={loadingFile && <CircularProgress size={18} />}
          >
            {translate('sales.optimize.btn.excelOptimizeRoutes')}
          </Button>
        </Grid>
        <Grid item md={2} xs={12} className="text-right-sm">
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => goToOrdersAll(selected?.length > 0 ? 0 : 1)} 
            disabled={loadingFileAll}
            startIcon={loadingFileAll && <CircularProgress size={18} />}
          >
            {translate('sales.optimize.btn.excelOrdersAll')}
          </Button>
        </Grid>
      </Grid>
      <div className="marginFiltersTable" style={{ paddingBottom: 10 }}>
        <SalesTableFilters
          onFilters={onFilters}
          list={list?.data}
          haveData={haveData}
          setHaveData={setHaveData}
          getSales={getSales}
          closeCreatePiboxButton={closeCreatePiboxButton}
          order={order}
          setOrder={setOrder}
        />
      </div>

      {list && (
        <SalesTable
          data={list}
          handleChangeCheck={handleChangeCheck}
          getSales={getSales}
          recordsPerPage={per_page}
          handleAddress={handleAddress}
          order={order}
          setOrder={setOrder}
          filters={filters}
          closeCreatePiboxButton={closeCreatePiboxButton}
        />
      )}

      {loading && <Loading />}

      <Slide direction="up" in={showButton} mountOnEnter unmountOnExit>
        {filters?.store_name ? (
          <Button
            onClick={goToCreatePibox}
            color="primary"
            variant="contained"
            className="fixed-bottom"
            size="small"
            disabled={
              filters?.store_name && selected?.length === 1 ? false : true
            }
          >
            {translate('app.createService')}
          </Button>
        ) : (
          <Button
            onClick={goToCreatePibox}
            color="primary"
            variant="contained"
            className="fixed-bottom"
            size="small"
          >
            {translate('app.createService')}
          </Button>
        )}
      </Slide>

      <Sidebar side="right" open={open} setOpen={setOpen} width="300px">
        <Form sale={updateAddress} getSales={getSales} setOpen={setOpen} />
      </Sidebar>

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
};

export default Sales;